import { CheckCircleOutlined, DeleteOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useFormikContext } from 'formik';
import { EditUserPayload } from 'models/Users';

export const PrinterTable = (): JSX.Element => {
  const {
    values: { assignedPrinters },
    setFieldValue
  } = useFormikContext<EditUserPayload>();
  const columns: ColumnsType<{ name: string; dataAreaId: string }> = [
    {
      title: 'Data Area ID',
      dataIndex: 'dataAreaId',
      key: 'dataAreaId'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Printer Address',
      dataIndex: 'address',
      key: 'address'
    },
    {
      title: 'Network Port',
      dataIndex: 'port',
      key: 'port'
    },
    {
      title: 'Is Default',
      dataIndex: 'isDefault',
      key: 'isDefault',
      align: 'center',
      render: (isDefault: boolean) => (isDefault ? <CheckCircleOutlined style={{ color: 'green', fontSize: 18 }} /> : <MinusCircleOutlined style={{ color: 'red', fontSize: 18 }} />)
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      render: (text, record) => {
        return (
          <Button
            icon={<DeleteOutlined />}
            danger
            size="small"
            onClick={() => {
              setFieldValue(
                'assignedPrinters',
                assignedPrinters?.filter((warehouse) => warehouse.name !== record.name)
              );
            }}
          />
        );
      }
    }
  ];
  return <Table columns={columns} rowKey={(record) => record.name} dataSource={assignedPrinters} />;
};
