import { Col, Form, Row, Spin } from 'antd';
import { SwitchInput, TextInput } from 'components/UI/FormItems';
import { SelectInput } from 'components/UI/FormItems/SelectInput';
import { useFormikContext } from 'formik';
import { AuthorizedPrinters } from 'models/LegalEntities';
import React from 'react';
import { useGetLegalEntitiesQuery } from 'redux/services/maggieCadabby/maggieCadabbyApi';

const AssignPrinterFormComponent = (): JSX.Element => {
  const { setFieldValue, values } = useFormikContext<AuthorizedPrinters & { dataAreaId: string }>();
  const { data, isLoading, isFetching } = useGetLegalEntitiesQuery({});

  const printers = data?.data.find((le) => le.dataAreaId === values.dataAreaId)?.printers;

  return (
    <Form layout="vertical">
      <Row gutter={[8, 8]} style={{ marginTop: 0 }}>
        <Col span={24}>
          <Spin spinning={isLoading || isFetching}>
            <SelectInput value={values.dataAreaId} options={data?.data.map((address) => ({ label: address.dataAreaId, value: address.dataAreaId }))} fieldName="dataAreaId" label="Data Area Id" />
          </Spin>
        </Col>
        <Col span={24}>
          <SelectInput
            onChange={(val) => {
              const printer = printers?.find((printer) => printer.name === val);
              setFieldValue('name', val);
              setFieldValue('address', printer?.address);
              setFieldValue('port', printer?.port);
            }}
            value={values.name}
            fieldName="name"
            label="Printer Name"
            options={printers?.map((printer) => ({ label: printer.name, value: printer.name }))}
          />
        </Col>
        <Col span={24}>
          <TextInput disabled fieldName="address" label="Printer Address" />
        </Col>
        <Col span={24}>
          <TextInput disabled fieldName="port" label="Network Port" />
        </Col>
        <Col span={24}>
          <SwitchInput fieldName="isDefault" label="Is Default" />
        </Col>
      </Row>
    </Form>
  );
};

export const AssignPrinterForm = React.memo(AssignPrinterFormComponent);
