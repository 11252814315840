import { Col, Form, Input, Row, Select, Space, Spin, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { SwitchInput } from 'components/UI/FormItems';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetBusinessesQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { useGetAddressesQuery } from 'redux/services/chuckieSue/addressesApi';
import { LocationAddressTypes } from 'redux/services/chuckieSue/models/addresses';
import { setSelectedWarehouse } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';

export const AssignWarehouseForm = (): JSX.Element => {
  const { xs } = useBreakpoint();
  const { setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  const [selectedBusiness, setSelectedBusiness] = useState<string | undefined>();
  const { chuckieSueAddressesParams } = useAppSelector((state) => state);
  const { selectedWarehouse } = useAppSelector((state) => state.app);
  const { data: businessData, isLoading: isBusinessLoading, isFetching: isBusinessFetching } = useGetBusinessesQuery();
  const { data, isLoading, isFetching } = useGetAddressesQuery(
    { businessId: selectedBusiness as string, params: { ...chuckieSueAddressesParams, addressTypesInclude: [LocationAddressTypes.Warehouse], overrideSkipTake: true } },
    { skip: !selectedBusiness }
  );

  return (
    <Form layout="vertical">
      <Row gutter={[8, 8]} style={{ marginTop: 0 }}>
        <Col span={24}>
          <Spin spinning={isBusinessLoading} style={{ width: '100%' }}>
            <Space size={2} direction="vertical" style={{ width: '100%' }}>
              <Typography.Text>Business</Typography.Text>
              <Select
                showSearch
                style={{ width: '100%' }}
                options={businessData?.data?.map((address) => ({ label: address.name, value: address.id })).sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(val) => {
                  setSelectedBusiness(val);
                }}
                value={selectedBusiness}
                filterOption={(input, option) => (option ? option.label.toLowerCase().includes(input.toLowerCase()) : false)}
              />
            </Space>
          </Spin>
        </Col>
        <Col span={24}>
          <Spin spinning={isLoading || isFetching}>
            <Space size={2} direction="vertical" style={{ width: '100%' }}>
              <Typography.Text>Warehouse Address Id</Typography.Text>
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(val) => {
                  setFieldValue('warehouseAddressId', val);
                  dispatch(setSelectedWarehouse(data?.data.find((address) => address.id === val) as any));
                }}
                value={selectedWarehouse?.id}
                disabled={!selectedBusiness}
                options={data?.data.map((address) => ({ label: `${address.name} - ${address.code}`, value: address.id }))}
                filterOption={(input, option) => (option ? option.label.toLowerCase().includes(input.toLowerCase()) : false)}
              />
            </Space>
          </Spin>
        </Col>
        <Col span={24}>
          <Space size={2} direction="vertical" style={{ width: '100%' }}>
            <Typography.Text>Data Area Id</Typography.Text>
            <Input disabled value={selectedWarehouse?.business.dataAreaId} />
          </Space>
        </Col>
        <Col span={24}>
          <SwitchInput fieldName="isDefault" label="Is Default" />
        </Col>
      </Row>
    </Form>
  );
};
