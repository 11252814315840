import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import { EditUserPayload } from 'models/Users';
import React, { FC, useState } from 'react';
import { AssignPrinterForm } from './AssignPrinterForm';

const PrinterModalComponent: FC = () => {
  const [visiblePrinter, setVisiblePrinter] = useState(false);
  const {
    setFieldValue,
    values: { assignedPrinters }
  } = useFormikContext<EditUserPayload>();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      dataAreaId: 'MDSI',
      name: '',
      address: '',
      port: null
    },
    onSubmit: (values) => {
      setFieldValue('assignedPrinters', [...(assignedPrinters ?? []), values]);
      setVisiblePrinter(false);
      formik.resetForm();
    }
  });
  return (
    <>
      <Button
        onClick={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          setVisiblePrinter(true);
        }}
        icon={<PlusCircleOutlined />}>
        Assign Printer
      </Button>
      <div onClick={(ev) => ev.stopPropagation()}>
        <Modal
          destroyOnClose
          title="Assign Printer"
          visible={visiblePrinter}
          onCancel={() => {
            setVisiblePrinter(false);
            formik.resetForm();
          }}
          onOk={() => {
            formik.submitForm();
          }}>
          <FormikProvider value={formik}>
            <AssignPrinterForm />
          </FormikProvider>
        </Modal>
      </div>
    </>
  );
};

export const PrinterModal = React.memo(PrinterModalComponent);
